.gallery-view {
  padding: 20px;
  width: 100%;
  overflow: auto;
  background-color: #f3f9fe;
}

.gallery-title{
    text-align: center;
    font-family: 'Play';
    color: #34508e;
}

.gallery-video {
  width: 880px;
  height: 440px;
  display: inline-block;
  margin: 20px;
  padding-bottom: 160px;
}
.gallery-video-intro{
    margin-top: 10px;
    text-align: left;
    margin-bottom: 100px;
    padding-left: 5px;
}
.gallery-video-intro p{
    margin: 0;
}

.video-react .video-react-big-play-button{
    background-color: rgb(255, 77, 79) !important;
}

.gallery-video-title{
    font-weight: 800;
    font-size: 1.6rem;
    color: #3f3f3f;
}

.gallery-video-source{
    color: #606060;
    margin-bottom: 20px;
    display: inline-block;
    padding-right: 10px;
}

a{
    color: #34508e;
}