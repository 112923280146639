.header {
  height: 60px;
  border-bottom: solid 2px lightgray;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.pannelWrapper {
  display: flex;
  flex-direction: row;
  height: calc(100%);
  /* border-bottom : solid 1px lightgray */
}

.editPannel {
  flex: 1;
  overflow: scroll;
  background-color: #f4f5fa;
  border-right: solid 1px lightgray;
}

.chartPannel {
  flex: 1;
  margin: auto;
}

pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}

.clipPanel {
  color: #fff;
  min-width: 350px;
  background-color: #34508e;
  height: 100%;
  overflow-y: auto;
  cursor: default;
}

.clipList {
  /* background-color: azure; */
  height: calc(100% - 100px);
  /* overflow-y: auto; */
  /* padding-left: 2rem; */
}

.clipGroup {
  margin-bottom: 35px;
}

.clipType {
  font-family: "Play", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 900;
  padding-left: 2rem;
  padding-bottom: 7px;
  color: #c1cfd9;
}

.clipId {
  cursor: pointer;
  font-family: "Play", sans-serif;
  font-size: 20px;
  padding-bottom: 7px;
  padding-top: 7px;
  padding-left: 2rem;
}
.clipId:hover, .sample:hover {
  background-color: rgba(95, 122, 183, 0.5);
}


.autoclip {
  font-weight: bolder;
  font-size: 30px;
  color: #ffe3a0;
  margin-bottom: 1em;
  margin-top: 1em;
  font-family: "Montserrat";
  padding-left: 2rem;
}

.clipPage {
  background-color: #f3f9fe;
  /* margin: 0; */
  overflow-y: hidden;
  height: "100vh";
}

body {
  margin: 0;
  background-color: #f9f9f9;
  overflow: hidden;
}

.selected {
  background-color: #5f7ab7;
}

.gallery {
  margin-top: 20px;
  font-weight: bolder;
  font-size: 26px;
  padding: 7px 0px 7px 30px;
  color: #c1cfd9;
  font-family: "Play", sans-serif;
}
.sample{
    cursor: pointer;
    font-family: "Play", sans-serif;
    font-size: 20px;
    padding-bottom: 7px;
    padding-top: 7px;
    padding-left: 2rem;
    margin-bottom: 20px;
}
.sample-selected{
  background-color: #5f7ab7;
}

.library {
  margin-top: 20px;
  font-weight: bolder;
  font-size: 26px;
  padding: 7px 0px 7px 30px;
  margin-bottom: 20px;
  color: #c1cfd9;
  font-family: "Play", sans-serif;
}
